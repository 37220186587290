import * as React from 'react';
import { createStore, combineReducers, Reducer } from 'redux';

// type ValuesOf<T extends any[]> = T[number];

const MNEMONIC = ["always", "argument_check", "never"];
type Mnemonic = "always" | "argument_check" | "never";

export interface IStoreExtension {
    states: string[];
    mnemonic: Mnemonic;
    fn: (states: { [key: string]: any }, ...args: any[]) => any;
    resetCB?: () => any;
}
export interface IStoreExtensions {
    [key: string]: IStoreExtension;
}
export interface IReducerAndActions {
    reducer: Reducer;
    actions: {
        [key: string]: (...args: any[]) => any;
    };
}
export interface IReducersAndActions {
    [key: string]: IReducerAndActions;
}

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface WithStoreProps {
    store: any;
    store_actions: any;
    store_ext: any;
}

interface WithStoreHOCState {
    store: any;
}



let initReducer = (state = true) => {
    return state;
};

//overriding type because it seems the @types/redux do not correctly show that new reducers can be added in runtime.
let store = createStore(combineReducers({ store_initialized: initReducer } as { [key: string]: any; }));
let reduxDispatch = store.dispatch;
let reduxSubscribe = store.subscribe;
let reduxGetState = store.getState;


let self = this;
let state_keys: string[] = [];
let all_extensions: { [key: string]: (...args: any[]) => any; } = {};
let reducers_by_state_key: { [key: string]: Reducer; } = {};
let callbacks_by_state_key: { [key: string]: (() => void)[]; } = {};
let reset_cbs_by_ext_key: { [key: string]: (() => void); } = {};
let ext_keys_by_state_key: { [key: string]: string[]; } = {};

let ext_results_by_ext_key: {
    [key: string]: {
        value: any;
        args: any[];
    };
} = {};

let actions_by_state_key: {
    [key: string]: {
        [key: string]: (...args: any[]) => any;
    }
} = {};


function updateCaller() {
    state_keys.forEach((state_key) => {
        callbacks_by_state_key[state_key].forEach((cb) => {
            cb();
        });
    });
}

reduxSubscribe(() => {
    updateCaller();
});









function addReducerAndActions(state_key: string, reducer_and_actions: IReducerAndActions) {
    if (reducers_by_state_key.hasOwnProperty(state_key) || actions_by_state_key.hasOwnProperty(state_key)) {
        throw new Error("withStore: Cannot add state; state_key '" + state_key + "' already defined.");
    }
    let actions = reducer_and_actions.actions;
    if (!actions) {
        throw new Error("withStore: Missing actions in reducer_and_actions argument.");
    }
    actions_by_state_key[state_key] = actions;
    reducers_by_state_key[state_key] = reducer_and_actions.reducer;
    if (callbacks_by_state_key.hasOwnProperty(state_key) === false) {
        callbacks_by_state_key[state_key] = [];
    }
    if (ext_keys_by_state_key.hasOwnProperty(state_key) === false) {
        ext_keys_by_state_key[state_key] = [];
    }
    state_keys.push(state_key);

    let newRootReducer = combineReducers(reducers_by_state_key);
    store.replaceReducer(newRootReducer);

    let action_keys = Object.keys(actions);
    action_keys.forEach((action_key) => {
        let action = actions[action_key];
        if (action) {
            if (actions_by_state_key.hasOwnProperty(state_key) === false) {
                actions_by_state_key[state_key] = {};
            }

            //Create dynamic function that will call the action with first argument being the relevant state.
            //Cannot be an arrow function because they inherit '.arguments' from parent, not their own.
            let dispatch_wrapped_action = function () {
                let state_for_action = reduxGetState()[state_key];
                if (!state_for_action) {
                    console.error("withStore: Could not dispatch; the action's state missing.");
                    return;
                }
                //Clear extension results for given state.
                ext_keys_by_state_key[state_key].forEach((ext_key) => {
                    if (ext_results_by_ext_key[ext_key]) {
                        if (reset_cbs_by_ext_key.hasOwnProperty(ext_key)) {
                            reset_cbs_by_ext_key[ext_key]();
                        }
                        delete ext_results_by_ext_key[ext_key];
                    }
                });
                //Do redux dispatch to trigger reducer.
                reduxDispatch(action(...arguments, state_for_action));
            };
            //Ensure 'this' is correct so reduxGetState and reduxDispatch function inside dynamic func.
            dispatch_wrapped_action.bind(self);

            actions_by_state_key[state_key][action_key] = dispatch_wrapped_action;
        }
    });

    return {
        actions: actions_by_state_key[state_key],
        getState: () => { return reduxGetState()[state_key]; },
        addUpdateCallback: (cb: () => void) => { callbacks_by_state_key[state_key].push(cb); }
    };
}
function addReducersAndActions(reducers_and_actions: IReducersAndActions) {
    Object.keys(reducers_and_actions).forEach((state_key) => {
        addReducerAndActions(state_key, reducers_and_actions[state_key]);
    });
}








function addExts(extensions: IStoreExtensions) {
    Object.keys(extensions).forEach((ext_key) => {
        let ext = extensions[ext_key];
        if (ext.hasOwnProperty("states") === false || !ext.states || ext.states.length < 1) {
            throw new Error("withStore: Cannot add extension '" + ext_key + "' without specifying which states it subscribes to.");
        }
        if (ext.hasOwnProperty("fn") === false || !ext.fn) {
            throw new Error("withStore: Cannot add extension '" + ext_key + "' without specifying property 'fn'.");
        }
        if (ext.hasOwnProperty("mnemonic") === false || MNEMONIC.includes(ext.mnemonic) === false) {
            throw new Error("withStore: Invalid 'mnemonic' for extension '" + ext_key + "'. Valid are: " + MNEMONIC.join() + ".");
        }

        ext.states.forEach((state_key) => {
            if (ext_keys_by_state_key.hasOwnProperty(state_key) === false) {
                ext_keys_by_state_key[state_key] = [];
            }
            ext_keys_by_state_key[state_key].push(ext_key);
        });

        if (ext.resetCB) {
            reset_cbs_by_ext_key[ext_key] = ext.resetCB;
        }

        const wrapped_ext = function (): any {
            let state_refs: {
                [key: string]: any;
            } = {};
            let global_state: { [key: string]: any } = reduxGetState();
            if (ext.mnemonic === "always" || ext.mnemonic === "argument_check") {
                if (ext_results_by_ext_key.hasOwnProperty(ext_key) === false) {
                    ext.states.forEach((state_key) => {
                        state_refs[state_key] = global_state[state_key];
                    });
                    ext_results_by_ext_key[ext_key] = {
                        value: ext.fn(state_refs, ...arguments),
                        args: [].concat(...arguments)
                    };
                    return ext_results_by_ext_key[ext_key].value;
                }
                if (ext.mnemonic === "argument_check") {
                    let result = ext_results_by_ext_key[ext_key];
                    let result_is_invalid = (result.args.length !== arguments.length) || result.args.some((arg, i) => { return arg !== arguments[i]; });
                    if (result_is_invalid) {
                        ext.states.forEach((state_key) => {
                            state_refs[state_key] = global_state[state_key];
                        });
                        ext_results_by_ext_key[ext_key] = {
                            value: ext.fn(state_refs, ...arguments),
                            args: [].concat(...arguments)
                        };
                        return ext_results_by_ext_key[ext_key].value;
                    }
                }
                return ext_results_by_ext_key[ext_key].value;
            }
            ext.states.forEach((state_key) => {
                state_refs[state_key] = global_state[state_key];
            });
            return ext.fn(state_refs, ...arguments);
        };

        all_extensions[ext_key] = wrapped_ext;
    });
}





export function withStore<P extends WithStoreProps>(WrappedComponent: React.ComponentType<P>, states: string[] = []): React.ComponentClass<Omit<P, keyof WithStoreProps>> {

    class WithStoreHOC extends React.Component<P, WithStoreHOCState> {
        states_obj: { [k: string]: any} = {};

        //Implemented here because actions may be triggered between the construction of this object and its mounting.
        changeListener: () => void = () => {
            if (states.length === 0) {
                this.setState({ store: reduxGetState() });
            } else {
                const store = reduxGetState();
                if (states.some(state_key => this.states_obj[state_key] !== store[state_key])) {
                    //There has been a change in one or more states this component subscribes to.
                    this.states_obj = Object.keys(store).reduce((acc, curr) => {
                        acc[curr] = store[curr];
                        return acc;
                    }, {} as { [k: string]: any });
                    this.setState({ store: this.states_obj});
                }
            }
        }
        unsubscribe: (() => void) | null = null;

        constructor(props: P) {
            super(props);

            this.state = {
                store: reduxGetState()
            };

            //Subscribe returns a function that unsubs when invoked.
            //This is done in constructor because an action may be triggered by a child components own constructor.
            //The child constructor runs before the parents ComponentDidMount.
            this.unsubscribe = reduxSubscribe(this.changeListener);
        }

        componentWillUnmount() {
            if (this.unsubscribe) {
                this.unsubscribe();
            }
        }

        render() {
            return (
                <WrappedComponent
                    store={this.state.store}
                    store_actions={actions_by_state_key}
                    store_ext={all_extensions}
                    {...this.props}
                />
            );
        }
    }

    //Not exactly sure why this is necessary. :(
    return WithStoreHOC as unknown as React.ComponentClass<Omit<P, keyof WithStoreProps>>;
}





export namespace withStore {
    export const addState = (state_key: string, reducer_and_actions: IReducerAndActions) => {
        return addReducerAndActions(state_key, reducer_and_actions);
    };
    export const addStates = (reducers_and_actions: IReducersAndActions): void => {
        addReducersAndActions(reducers_and_actions);
    };
    export const addExtensions = (extensions: IStoreExtensions): void => {
        addExts(extensions);
    };
    export const actions = actions_by_state_key;
    export const extensions = all_extensions;
    export const getState = <StoreDefinition extends {}>() => {
        return reduxGetState() as unknown as StoreDefinition;
    };
    export const subscribe = reduxSubscribe;
}

export default withStore;
